import { Injectable } from "@angular/core";
import {
  NbAuthToken,
  NbAuthTokenParceler,
  NbTokenStorage,
} from "@nebular/auth";
import * as Secure from "secure-ls";
import { SharedService } from "../root-services/shared.service";
@Injectable()
export class CustomTokenStorageClass extends NbTokenStorage {
  private parceler;
  protected key: string;
  private secure = new Secure();
  constructor(
    parceler: NbAuthTokenParceler,
    private sharedService: SharedService
  ) {
    super();
    this.parceler = parceler;
    this.key = "UserThemePref";
  }
  /**
   * Returns token from localStorage
   * @returns {NbAuthToken}
   */
  get() {
    const raw = this.secure.get(this.key);
    return this.parceler.unwrap(raw);
  }
  /**
   * Sets token to localStorage
   * @param {NbAuthToken} token
   */

  /**
   * Sets token to localStorage
   * @param {NbAuthToken} token
   */
  set(token) {
    const raw = this.parceler.wrap(token);
    this.secure.set(this.key, raw);
    // localStorage.setItem(this.key, raw);
  }
  /**
   * Clears token from localStorage
   */
  clear() {
    this.secure.removeAll();
    localStorage.removeItem(this.key);
  }
}
