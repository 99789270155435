import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { NbTokenService } from "@nebular/auth";
import { ToasterService } from "./root-services/toaster.service";
import { Router } from "@angular/router";
import * as Secure from "secure-ls";

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
  secure = new Secure();
  constructor(
    protected tokenService: NbTokenService,
    private toasterService: ToasterService,
    private router: Router
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = this.secure.get("UserThemePref");

    if (token) {
      token = JSON.parse(this.secure.get("UserThemePref"));
      const cloned = req.clone({
        headers: req.headers.set("Authorization", "JWT " + token.value),
      });

      return next.handle(cloned).pipe(
        catchError((err: HttpErrorResponse) => {
          if (err && err.status === 401) {
            this.tokenService.clear();
            localStorage.removeItem("app_run_time");
            window.location.reload();
          } else {
            if (this.router.url !== "/auth/login") {
              this.toasterService.showToast(
                "danger",
                "حدث مشكلة ما",
                err.error?.result ?? ""
              );
            }
            return next.handle(cloned);
          }
        })
      );
    } else {
      return next.handle(req).pipe();
    }
  }
}
