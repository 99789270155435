import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { User } from "../pages/models/users";
import { UserService } from "../pages/services/users.service";
import { SharedService } from "./shared.service";
import { ToasterService } from "./toaster.service";

@Injectable({
  providedIn: "root",
})
export class UserResolverService implements Resolve<User[]> {
  constructor(
    private userService: UserService,
    private toaster: ToasterService,
    private sharedService: SharedService
  ) {}
  resolve(route: ActivatedRouteSnapshot): Observable<User[]> | User[] {
    if (this.sharedService.getUser()) {
      return [this.sharedService.getUser()];
    } else {
      return this.userService.getInfo().pipe(
        catchError((error) => {
          return of(null);
        })
      );
    }
  }
}
