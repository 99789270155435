import { Component, OnInit } from "@angular/core";
import { NbSidebarService, NbThemeService } from "@nebular/theme";

@Component({
  selector: "ngx-cog-settings",
  styleUrls: ["./cog-settings.component.scss"],
  templateUrl: "./cog-settings.component.html",
})
export class CogSettingsComponent implements OnInit {
  sidebar: any = document.getElementsByTagName("nb-sidebar");
  menuItems: any = document.getElementsByClassName("menu-title");
  selectedTheme = localStorage.getItem("theme");

  themes = [
    { name: "orange-theme", active: false },
    { name: "cyan-theme", active: false },
    { name: "green-theme", active: false },
    { name: "red-theme", active: false },
  ];
  lightSidebarColors = [
    { name: "sidebar-background-color", value: "#fff" },
    { name: "sidebar-text-color", value: "#222b45" },
  ];
  darkSidebarColors = [
    { name: "sidebar-background-color", value: "#222b45" },
    { name: "sidebar-text-color", value: "#fff" },
  ];

  constructor(
    private themeService: NbThemeService,
    private sidebarService: NbSidebarService
  ) {}
  ngOnInit() {
    this.themes.forEach((theme) => {
      if (theme.name === this.selectedTheme) {
        theme.active = true;
      }
    });
  }

  changeTheme(theme) {
    if (theme?.name) {
      if (localStorage.getItem("theme") === theme.name) return;
      this.themes.map((theme) => (theme.active = false));
      theme.active = true;
      localStorage.setItem("theme", theme.name);
      this.themeService.changeTheme(theme.name);
    } else {
      localStorage.setItem("theme", theme);
      this.themeService.changeTheme(theme);
    }
  }
  changeSideBarColor(color: string) {
    if (color === "light") {
      this.sidebar[0].style.background = "#fff";
      for (let i = 0; i < this.menuItems.length; i++) {
        this.menuItems[i].style.color = "#222b45";
      }
    } else {
      this.sidebar[0].style.background = "#222b45";
      for (let i = 0; i < this.menuItems.length; i++) {
        this.menuItems[i].style.color = "#fff";
      }
    }
  }
  hideOrShowMainSidebar(checked: boolean) {
    if (checked) this.sidebarService.collapse("menu-sidebar");
    else this.sidebarService.expand("menu-sidebar");
  }
  hideOrShowFooter(checked: boolean) {
    let footer = document.getElementsByTagName(
      "nb-layout-footer"
    ) as HTMLCollectionOf<HTMLElement>;
    if (checked) {
      footer[0].style.display = "none";
    } else footer[0].style.display = "flex";
  }
}
