import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ThemeModule } from "./@theme/theme.module";
import { AppComponent, ApiEndPoint } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import {
  NbDatepickerModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from "@nebular/theme";
import { CoreModule } from "./@core/core.module";
import {
  NbAuthJWTInterceptor,
  NbAuthModule,
  NbPasswordAuthStrategy,
  NbTokenStorage,
} from "@nebular/auth";
import { AuthGuard } from "./guards/auth-guard.service";
import { BearerInterceptor } from "./bearer-ineterceptor";
import { SharedService } from "./root-services/shared.service";
import { CustomTokenStorageClass } from "./auth/custom-local-storage";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: "email",
          baseEndpoint: ApiEndPoint,
          login: {
            method: "post",
            endpoint: "auth/token/",
            redirect: {
              success: "/pages",
              failure: null,
            },
          },
          logout: {
            endpoint: "",
          },
          token: {
            key: "token",
          },
        }),
      ],
      forms: {},
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: NbTokenStorage, useClass: CustomTokenStorageClass },

    SharedService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NbAuthJWTInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {}
