import { AcademicService } from './../pages/services/academic.service';
import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ApiEndPoint } from "../app.component";
import { User } from "../pages/models/users";
import * as Secure from "secure-ls";

export interface StudyYearObject {
  selectedStudyYear: StudyYear;
  studyYears: StudyYear[];
}

export interface StudyYear {
  id: number;
  title: string;
}

@Injectable()
export class SharedService implements OnDestroy {
  user: User;
  secure = new Secure();

  private studyYearObjcet = new BehaviorSubject<StudyYearObject>(
    {} as StudyYearObject
  );

  getstudyYear = this.studyYearObjcet.asObservable();

  constructor(private http: HttpClient) {
  }

  setUser(user: User) {
    this.secure.set("app_run_time", JSON.stringify(user));
  }
  getUser(): User {
    let usr = this.secure.get("app_run_time");
    if (usr) return JSON.parse(usr);
    else return usr;
  }

  setStudyYear(studyYear: StudyYearObject) {
    this.studyYearObjcet.next(studyYear);
  }

  listStudyYearsFromApi() {
    return this.http.get(`${ApiEndPoint}academic/studyYear/list/`);  
  }
  ngOnDestroy() {
    this.studyYearObjcet.unsubscribe();
  }



}
