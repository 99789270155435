import { Component, OnDestroy, OnInit } from "@angular/core";
import { NbTokenService } from "@nebular/auth";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { User } from "../../../pages/models/users";
import { SharedService } from "../../../root-services/shared.service";

@Component({
  selector: "ngx-one-column-layout",
  styleUrls: ["./one-column.layout.scss"],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-sidebar cog tag="cog-sidebar" state="collapsed" fixed>
        <ngx-cog-settings></ngx-cog-settings>
      </nb-sidebar>
      <nb-layout-column>
        <div
          class="ngx-toggle-setting-container "
          [ngClass]="!sidebarCollapsed ? 'position-end expanded' : ''"
          (click)="toggle()"
          *ngIf="!hideCogSettings"
        >
          <button nbButton id="cog-button">
            <nb-icon icon="cogs" id="cog-icon"></nb-icon>
          </button>
        </div>

        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnInit, OnDestroy {
  sidebarCollapsed = true;
  private destroy$: Subject<void> = new Subject<void>();
  user: User;
  hideCogSettings = false;
  constructor(
    private nbTokenService: NbTokenService,
    private sidebarService: NbSidebarService,
    private breakpointService: NbMediaBreakpointsService,
    private menuService: NbMenuService,
    private themeService: NbThemeService
  ) {}
  ngOnInit() {
    this.sidebarBreakpointObserver();
  }
  sidebarBreakpointObserver() {
    const { md, sm } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => {
          return [currentBreakpoint.width < md, currentBreakpoint.width < sm];
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((zeroMdOneSm: boolean[]) => {
        this.hideCogSettings = zeroMdOneSm[0];
        this.sidebarService.collapse("cog-sidebar");
        this.menuService.onItemClick().subscribe((men) => {
          if (zeroMdOneSm[1]) this.sidebarService.compact("menu-sidebar");
        });
        this.sidebarCollapsed = true;
      });
  }
  toggle() {
    this.sidebarCollapsed = !this.sidebarCollapsed;

    this.sidebarService.toggle(false, "cog-sidebar");
  }

  logout() {
    this.nbTokenService.clear();
    window.location.reload();
  }
  ngOnDestroy() {
    this.destroy$.unsubscribe();
  }
}
