import { Injectable } from "@angular/core";
import {
  NbToastrService,
  NbGlobalPosition,
  NbGlobalPhysicalPosition,
  NbComponentStatus,
} from "@nebular/theme";

@Injectable({
  providedIn: "root",
})
export class ToasterService {
  constructor(private toastrService: NbToastrService) {}
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  status: NbComponentStatus = "primary";
  showToast(
    type?: NbComponentStatus,
    title?: string,
    body?: string,
    duration?: number
  ) {
    if (!type) {
      type = "success";
      title = "تمت العملية بنجاح";
    }
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: duration ?? this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? `${title}` : "";
    this.toastrService.show(body, `${titleContent}`, config);
  }
}
