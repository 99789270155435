import { Component } from "@angular/core";

@Component({
  selector: "ngx-footer",
  template: `
    <span class="created-by">
      مطور من قبل
      <b><a href="https://gaido.io" target="_blank">كايدو للبرمجيات</a></b>
    </span>
  `,
})
export class FooterComponent {}
