import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "iq",
})
export class IqPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value != null) return value + " د.ع ";
  }
}
