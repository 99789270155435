import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiEndPoint } from "../../app.component";
import { User } from "../models/users";

@Injectable({
  providedIn: "root",
})
export class UserService {
  authToken: string = ApiEndPoint + "auth/token/";
  httpHeader: HttpHeaders;
  base: string = ApiEndPoint + "users/";
  info: string = this.base + "info/";
  list: string = this.base + "list/";
  create: string = this.base + "create/";
  update: string = this.base + "edit/";
  resetPass: string = this.base + "resetPaswd/";

  constructor(private http: HttpClient) {}

  listUsers() {
    return this.http.get<User[]>(this.list, { headers: this.httpHeader });
  }
  getInfo() {
    return this.http.get<User[]>(this.info, { headers: this.httpHeader });
  }
  listInstructors() {
    return this.http.get(this.base + "instructor/list/", {
      headers: this.httpHeader,
    });
  }
  changePassword(data) {
    return this.http.post(this.base + "changPaswd/", data, {
      headers: this.httpHeader,
    });
  }
  deleteUserFinal(data: User) {
    return this.http.delete(this.base + "editUsr/" + data.id + "/");
  }
  getAuth(data: User) {
    return this.http.post(
      this.base + "getAuth/",
      {
        username: data.auth_username,
        fullname: data.fullname,
        email: data.email,
      },
      { headers: this.httpHeader }
    );
  }
  updateUser(data: User) {
    return this.http.post(
      this.update,
      {
        id: data.auth,
        username: data.auth_username,
        fullname: data.fullname,
        email: data.email,
        active: data.auth_is_active,

        idNumber: data.idNumber,
        registerNumber: data.registerNumber,
        pageNumber: data.pageNumber,
        idIssued: data.idIssued,
      },
      { headers: this.httpHeader }
    );
  }

  createUser(data: User) {
    return this.http.post(this.create, data, { headers: this.httpHeader });
  }

  updateUserFinal(data: User) {
    return this.http.put(this.base + "editUsr/" + data.id + "/", data, {
      headers: this.httpHeader,
    });
  }

  resetPassword(id: number) {
    return this.http.post(this.resetPass, id, { headers: this.httpHeader });
  }

  disableUsr(data: User) {
    return this.http.put(this.base + "editUsr/" + data.id + "/", data, {
      headers: this.httpHeader,
    });
  }
}
