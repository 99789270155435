import { Component, OnInit } from "@angular/core";
import { NbIconLibraries, NbThemeService } from "@nebular/theme";
// export const ApiEndPoint = "http://127.0.0.1:8000/api/";
// export const ApiEndPoint = "https://schooltry.gaido.io/api/";
export const ApiEndPoint = "https://ks.ebtasem.pro/api/";

@Component({
  selector: "ngx-app",
  template: "<router-outlet></router-outlet>",
})
export class AppComponent implements OnInit {
  constructor(
    private iconLibraries: NbIconLibraries,
    private themeService: NbThemeService
  ) {
    this.iconLibraries.registerFontPack("font-awesome", {
      packClass: "fas",
      iconClassPrefix: "fa",
    });

    this.iconLibraries.setDefaultPack("font-awesome");
  }

  ngOnInit(): void {
    if (localStorage.getItem("theme")) {
      this.themeService.changeTheme(localStorage.getItem("theme"));
    }
  }
}
