<div class="fade show active">
  <div class="p-15 divider">
    <p class="text-hint">اختر تنسيقا</p>
    <div class="flex flex-wrap hiddenradio">
      <div class="flex flex-col" (click)="changeTheme('default')">
        <label
          ><input type="radio" /><img src="assets/images/light.png"
        /></label>
        <div class="mt-1 text-md text-center">فاتح</div>
      </div>
      <div class="flex flex-col mt-3" (click)="changeTheme('dark')">
        <label
          ><input type="radio" /><img src="assets/images/dark.png"
        /></label>
        <div class="mt-1 text-md text-center">مظلم</div>
      </div>
    </div>
  </div>

  <div class="p-15 divider">
    <p class="text-hint">لون الشريط الجانبي</p>
    <button
      nbTooltip="مظلم"
      nbButton
      size="small"
      status="primary"
      class="sidebar-bg-button ml-1"
      (click)="changeSideBarColor('cosmic')"
    >
      <nb-icon icon="moon"> </nb-icon>
    </button>
    <button
      nbButton
      nbTooltip="فاتح"
      size="small"
      status="primary"
      class="sidebar-bg-button ml-1"
      (click)="changeSideBarColor('light')"
    >
      <nb-icon icon="sun" class="warning"> </nb-icon>
    </button>
  </div>
  <div class="p-15 divider">
    <p class="text-hint">إخفاء الشريط الجانبي</p>
    <nb-toggle (checkedChange)="hideOrShowMainSidebar($event)"></nb-toggle>
  </div>
  <div class="p-15 divider">
    <p class="text-hint">إخفاء الهامش</p>
    <nb-toggle (checkedChange)="hideOrShowFooter($event)"></nb-toggle>
  </div>
  <!-- <div class="p-15 divider">
    <p class="text-hint">اللون الأساسي</p>

    <div class="theme-setting-options">
      <ul class="choose-theme list-unstyled mb-0">
        <div class="backgrounds-pickers">
          <ng-container *ngFor="let theme of themes">
            <div
              (click)="changeTheme(theme)"
              [class]="
                'p-2 d-inline-block rounded mr-1 cursor-pointer  ' + theme.name
              "
              [class.active-box]="theme.active"
            ></div>
          </ng-container>
        </div>
      </ul>
    </div>
  </div> -->
</div>
